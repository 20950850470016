<template>
    <zw-sidebar @shown="shown"
                :title="form.id ? $t('common.form.title.editSalesAttachment')  + ' (' + form.id + ')' : $t('common.form.title.createSalesAttachment')"
    >
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <zw-attachments v-model="attachments"
                                :offering-id="payload.offeringId"
                                :customer-id="payload.customerId"
                                modalType="salesAttachment"
                />
                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwMedia from "../../components/ZwMedia";
import ZwAttachments from "@/components/ZwAttachments";


export default {
    name: 'sales-attachemnt-modal',
    components: {ZwMedia, ZwAttachments},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                name: '',
                value: '',
            },
            attachments: [],
            selected: [],
            form: {},
            labelPrefix: 'invoice.attachment.label.',
        }
    },
    methods: {
        shown() {
            this.form.id = this.payload.id
            this.loading = false;
        },
        onSubmit() {
            this.loading = true;
            for (const [key, attach] of Object.entries(this.attachments)) {
                if (attach.type != "offering_attachment") {
                    this.selected.push({id: attach.id})
                }
            }
            this.$store.dispatch('Sales/saveAttachments', {'ids': this.selected, offering_id: this.payload.offeringId})
                .then((response) => {
                    this.$root.$children[0].closeModal(this.$children[0].$refs['sidebar'])
                    this.callback();
                })
                .finally(() => {
                    this.loading = false;
                })

        },
    }
}
</script>